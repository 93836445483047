import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween)

import { AnlopLine, createHeader, createLineItem, createPortCallId, populateLine } from "./anlopLine";

// Date range picker included from http://www.daterangepicker.com/
/*
<script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
*/
declare global {
    interface Window {
        boats: AnlopLine[];
        lastUpdatedBoats: number,
        $: any
    }
}

export const mandalPortIds: number[] = [9080, 3801, 3802, 3803, 3804, 3805];
export const lindesnesPortIds: number[] = [9090, 3901];

export class AnlopTable {
    public filterCruiseShips: boolean = false;
    public startDate: Dayjs = dayjs().startOf('day');
    public endDate: Dayjs = dayjs().add(7, 'days').endOf('day');
    public filterShipName: string = '';
    public filterPort: string = '';
    public filterTerminal: string = '';
    public container: HTMLElement | null;
    public filtersContainer: HTMLElement | null;
    public noBoats: HTMLElement | null;
    public header: HTMLElement | null;
    public lines: HTMLElement[] = [];
    public filtered: HTMLElement[] = [];
    public lastUpdated: Dayjs;
    public data: AnlopLine[];
    constructor(container: HTMLElement) {
        this.lastUpdated = dayjs.unix(window.lastUpdatedBoats);
        this.container = container;
        this.noBoats = this.container?.querySelector('.no-boats') as HTMLElement;
        this.header = this.container?.querySelector('.header') as HTMLElement;
        this.getFiltersContainer();
        this.renderLines();
        this.initPortFilters();
        if (this.filtersContainer) {
            this.initTermanalSearch(this.filtersContainer.querySelector('.SearchByTerminal'));
            this.initVesselSearch(this.filtersContainer.querySelector('.SearchByShip'));
            this.initCruiseShipToggle(this.filtersContainer.querySelector('.ShowOnlyCruiseShips'));
            this.initDateRangePicker();
            this.applyFilters();
        } else {
            // Theres no filters so we should display everything
            this.lines.forEach(line => line.style.display = "block")
            if (this.lines.length === 0) {
                if (this.noBoats !== null) {
                    this.noBoats.style.display = 'block';
                }
            } else {
                if (this.noBoats !== null) {
                    this.noBoats.style.display = 'none';
                }
            }
        }


        // show the container
        const index = this.container.querySelector('.anlopsindex');
        if (index !== null) {
            (index as HTMLElement).style.display = 'block'
        }
        console.log(this.data)
        let krs = this.data.filter(anlopLine => anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode && !mandalPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
            && !lindesnesPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode))
        let lind = this.data.filter(anlopLine => anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode && lindesnesPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode))
        let man = this.data.filter(anlopLine => anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode && mandalPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode))
        let undef = this.data.filter(anlopLine => !anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
        console.log("Total:", this.data.length);
        console.log("Krs", krs);
        console.log("Lin", lind);
        console.log("Man", man);
        console.log("Undefined", undef);


    }

    public getFiltersContainer(): void {
        this.filtersContainer = this.container!.querySelector('.filters');
    }



    public renderLines(): void {

        if (this.container === null) {
            return;
        }


        this.lines = Array.from(this.container.querySelectorAll('.anlopsitem')).filter(item => item && !item?.classList?.contains('header')).map(item => item as HTMLElement);
        this.data = this.lines.map((lineItem) => JSON.parse(lineItem?.dataset?.portCall || '{}'));
        // if (this.lastUpdated) {
        //     let lastUpdatedInfo = document.createElement('i');
        //     lastUpdatedInfo.innerHTML = `Sist oppdatert: ${this.lastUpdated.format('DD.MM.YYYY HH:mm')}`;
        //     this.filtersContainer?.appendChild(lastUpdatedInfo);
        // }
    }

    public applyFilters(): void {
        let sw = true;
        let active = 0;
        this.data.forEach((anlopLine: AnlopLine) => {
            // console.log(anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
            const show = (!this.filterTerminal || this.filterTerminal === '' || anlopLine.quayVisit![0]?.quayUsage?.name?.toLowerCase().includes(this.filterTerminal.toLowerCase())) // Terminal
                && (!this.filterShipName || this.filterShipName === '' || anlopLine?.vessel?.name?.toLowerCase().includes(this.filterShipName?.toLowerCase())) //Vessel
                && ((!this.filterPort || this.filterPort === '') || (
                    (this.filterPort === 'krs' && !anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
                    || (this.filterPort === 'krs' && anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode
                        && !mandalPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
                        && !lindesnesPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
                    )
                    || (
                        this.filterPort === 'lin'
                        && anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode
                        && lindesnesPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
                    )
                    || (
                        this.filterPort === 'man'
                        && anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode
                        && mandalPortIds.some(i => i == anlopLine.quayVisit[0]?.quayUsage?.parentObjectCode)
                    )
                )) //Port
                && (!this.filterCruiseShips || anlopLine.vessel?.vesselType?.toLowerCase().includes('cruise')) //CruiseShips
                && (this.startDate.isBefore(dayjs(anlopLine.arrivalTime))) // start date
                && (anlopLine.arrivalTime && dayjs(anlopLine.arrivalTime).isBetween(dayjs(this.startDate), dayjs(this.endDate))) // end date
            const line = this.lines.find(htmlLine => parseInt(htmlLine?.dataset?.id || "0") === anlopLine?.portCallId);

            if (!line) {
                return;
            }

            if (!show) {

                (line as HTMLElement).style.display = 'none';
            } else {
                (line as HTMLElement).style.display = 'block';
                active++;
                if (sw) {
                    line.classList.add("greystripe");
                } else {
                    line.classList.remove("greystripe");
                }
                sw = !sw;
            }

            if (active === 0) {
                if (this.noBoats !== null) {
                    this.noBoats.style.display = 'block';
                }
                if (this.header !== null) {
                    this.header.style.display = 'none';

                }

            } else {
                if (this.noBoats !== null) {
                    this.noBoats.style.display = 'none';
                }
                if (this.header !== null) {
                    this.header.style.display = 'block';

                }
            }


        });


    }



    public initPortFilters() {
        if (this.filtersContainer === null) {
            return;
        }
        const portFilters = this.filtersContainer.querySelector('.filters-ports');
        if (portFilters === null) {
            return;
        }
        const buttons = portFilters.querySelectorAll('button');
        if (buttons === null) {
            return;
        }

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                button.classList.add('active-filter');
                this.filterPort = button.dataset.filterValue || '';
                buttons.forEach(toRemoveClass => {
                    if (toRemoveClass !== button) {
                        toRemoveClass.classList.remove('active-filter');
                    }
                })
                this.applyFilters();
            })
        })
    }

    public initVesselSearch(input: HTMLElement | null) {
        if (input === null) {
            return;
        }
        const lines: AnlopLine[] = window.boats;
        input.addEventListener('input', (e) => {
            this.filterShipName = (e.target as HTMLInputElement).value;
            this.applyFilters()

        });
    }

    public initTermanalSearch(input: HTMLElement | null) {
        if (input === null) {
            return;
        }
        const lines: AnlopLine[] = window.boats;
        input.addEventListener('input', (e) => {
            this.filterTerminal = (e.target as HTMLInputElement).value;
            this.applyFilters()
        });
    }

    public initCruiseShipToggle(input: HTMLElement | null) {
        if (input === null) {
            return;
        }
        input.addEventListener('change', (e) => {
            this.filterCruiseShips = (e.target as HTMLInputElement).checked;
            this.applyFilters()

        });
    }

    public initDateRangePicker() {
        let earliest = this.data.reduce((result, item) => {

            if (item.arrivalTime && dayjs(item.arrivalTime).isBefore(result)) {
                result = dayjs(item.arrivalTime)
            }
            return result;
        }, dayjs());
        let latest = this.data.reduce((result, item) => {

            if (item.arrivalTime && dayjs(item.arrivalTime).isAfter(result)) {
                result = dayjs(item.departureTime)
            }
            return result;
        }, dayjs());

        window.$('.SearchByDate').daterangepicker({
            startDate: this.startDate.toDate(),
            endDate: this.endDate.toDate(),
            minDate: earliest.toDate(),
            maxDate: latest.toDate(),
            locale: {
                format: 'DD.MM.YYYY'
            }

        }, (start: any, end: any, label: string) => {
            this.startDate = dayjs(start.toDate());
            this.endDate = dayjs(end.toDate());
            this.applyFilters();
        });

    }
}

export class WeatherFilters {
    public ports: string[] = [];
    public container: HTMLElement;
    public currentPort: number = 0;

    constructor(container: HTMLElement) {
        this.container = container;
        container.querySelectorAll('.filters-ports button').forEach((button, index) => {
            let filterVal = (button as HTMLElement).dataset.filterValue;
            if (filterVal) {
                this.ports.push(filterVal)
                if (button.classList.contains('active-filter')) {
                    this.currentPort = index;
                }
                button.addEventListener('click', (e) => {
                    console.log('clicked', e)

                    let button = e.target as HTMLElement;
                    let buttonVal = button.dataset.filterValue;
                    if (buttonVal) {
                        this.selectPort(buttonVal);
                    }
                })
            }

        });
        this.render();
    }

    public selectPort(port: string): void {
        console.log(port)
        this.currentPort = this.ports.indexOf(port);
        this.render();
    }

    public render(): void {
        let itemsContainer = this.container.querySelector('.weather-items-container') as HTMLElement;
        this.container.querySelectorAll('.filters-ports button').forEach((button, index) => {
            if (index === this.currentPort) {
                button.classList.add('active-filter')
            } else {
                button.classList.remove('active-filter')

            }
        });
        itemsContainer.style.opacity = '0';
        setTimeout(() => {


            let childNodes = Array.from(this.container.querySelectorAll('.weatherdata-flex'));
            childNodes.forEach((node, index) => {
                let dataValue = (node as HTMLElement).dataset.filterPort;

                if (this.ports[this.currentPort] === dataValue) {
                    (node as HTMLElement).style.display = 'flex';
                }
                else {
                    (node as HTMLElement).style.display = 'none';

                }
            })

            itemsContainer.style.display = 'flex';

            setTimeout(() => {
                itemsContainer.style.opacity = '1';

            }, 1);


        }, 300);


    }
}

const init = () => {
    Array.from(document.querySelectorAll('.innhold-anlopsoversikt')).forEach(cont => new AnlopTable(cont as HTMLElement))
    Array.from(document.querySelectorAll('.innhold-vaerstasjoner')).forEach(cont => new WeatherFilters(cont as HTMLElement))

}
if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(init, 1);
} else {
    document.addEventListener("DOMContentLoaded", init);
}
